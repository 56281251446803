.cellPadding {
  padding: 2px;
}

.imageContainer {
  width: 72px;
  height: 72px;
  background: #f5f5f5;
  border-radius: 6px;
  overflow: hidden;
  display: inline-block;
}